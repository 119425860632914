<template>
    <div class="list-group-item" v-if="!subtask.is_finished">
        <div class="row pt-1">
            <div class="col-6">
                <div class="custom-checkbox custom-control custom-control-inline mr-0">
                    <input type="checkbox" class="custom-control-input" :id="`st_finished_cb_${subtask.id}`" v-model="subtask.is_finished" @change="finishItem()">
                    <label class="custom-control-label" :for="`st_finished_cb_${subtask.id}`">.</label>
                </div>
            </div>

            <div class="col-6 text-right">
                <s-parts-widget :subtask="subtask" :timers="timers" :project_id="project_id" @edit_description="$refs.description_editable.editValue()" />
            </div>
        </div>

        <h4 class="mt-2 card-title text-primary">
            <text-editable :styles="{'min-width': '300px', 'max-width': '500px'}" :editable="subtask.title" @update:editable="editItem({key: 'title', value: $event})">
                <span class="">{{ subtask.title }}</span>
            </text-editable>
        </h4>

        <div class="mb-1 description" v-if="subtask.description">
            <textarea-editable ref="description_editable" :object="{ project_id: project_id, task_id: subtask.task_id, subtask_id: subtask.id }" :styles="{ width: '100%' }" :editable="subtask.description" @update:editable="editItem({key: 'description', value: $event})">
                <div v-html="subtask.description"></div>
            </textarea-editable>
        </div>
    </div>
</template>

<script>
import TextEditable from '@/components/editable/TextEditable.vue'
import TextareaEditable from '@/components/editable/TextareaEditable.vue'

import SPartsWidget from '@/components/subtask/_parts/Widget.vue'

export default {
    name: 'SubtaskCard',
    props: ['subtask', 'timers', 'project_id'],
    methods: {
        async editItem(options = {}) {
            let {key, value} = options

			if(key) {
                this.$set(this.subtask, key, value)
                
                await this.$orm.mutate({
                    subtask: {
                        update: {
                            where: { id: { _eq: this.subtask.id }},
                            _set: { [key]: value }
                        }
                    },
                    project: {
                        update: {
                            where: { id: { _eq: this.project_id } },
                            _set: { updated_at_trigger: true }
                        }
                    },
                    task: {
                        update: {
                            where: { id: { _eq: this.subtask.task_id } },
                            _set: { updated_at_trigger: true },
                            fields: ['id']
                        }
                    }
                })
            }
        },
        async finishItem() {
            let _set = { is_finished: this.subtask.is_finished }
            if(_set.is_finished === true) {
                _set.finished_by_id = this.$store.state.auth.user.id
                _set.finished_at = 'now()'
            }

            await this.$orm.mutate({
                subtask: {
                    update: {
                        where: { id: { _eq: this.subtask.id }},
                        _set
                    }
                },
                project: {
                    update: {
                        where: { id: { _eq: this.project_id } },
                        _set: { updated_at_trigger: true }
                    }
                },
                task: {
                    update: {
                        where: { id: { _eq: this.subtask.task_id } },
                        _set: { updated_at_trigger: true },
                        fields: ['id']
                    }
                }
            })
        },
    },
    components: {
        TextEditable,
        TextareaEditable,
        SPartsWidget
    },
}
</script>

<style scoped>
.sub-task-list .custom-checkbox label {
	color: rgba(0, 0, 0, 0);
	cursor: pointer;
}
.list-group-item {
    padding: 0.5rem 1rem;
}
</style>