<template>
    <div class="row">
        <div class="col-6">
            <div v-if="subtask.finished_at" class="finish-date">{{ format_date(subtask.finished_at) }}</div>
        </div>

        <div class="col-6 text-right">
            <disabled-timer-badge :object="{ project_id, task_id: subtask.task_id, subtask_id: subtask.id }" :timers="timers"/>

            <a title="Удалить подзадачу" @click.prevent="deleteItem()" class="badge badge-outline-secondary redish-badge">
                <i v-if="!loading.delete" class="mdi mdi-delete"></i>
                <i v-else class="spinner-border spinner-border-sm"></i>
            </a>
        </div>
    </div>
</template>

<script>
import dayjs from '@/plugins/day'
import DisabledTimerBadge from '@/components/timer/DisabledTimerBadge.vue' 

export default {
    name: 'SubtaskCardWidget',
    props: ['subtask', 'timers', 'project_id', 'description_editable'],
    data: () => {
        return {
            loading: {
                delete: false
            }
        }
    },
    methods: {
        format_date(date) {
			return dayjs(date).format('DD.MM HH:mm');
		},
        async deleteItem() {
            if(!confirm(`Delete ${this.subtask.title} ?`))
                return false

            this.loading.delete = true
            var [err, response] = await this.$orm.mutate({
                subtask: {
                    update: {
                        where: { id: { _eq: this.subtask.id }},
                        _set: { is_deleted: true }
                    }
                },
                project: {
                    update: {
                        where: { id: { _eq: this.project_id } },
                        _set: { updated_at_trigger: true }
                    }
                },
                task: {
                    update: {
                        where: { id: { _eq: this.subtask.task_id } },
                        _set: { updated_at_trigger: true },
                        fields: ['id']
                    }
                }
            })
            if(!err && !response.error)
                this.subtask.is_deleted = true
            
            this.loading.delete = false
        }
    },
    components: {
        DisabledTimerBadge
    }
}
</script>

<style scoped>
div {
    font-size: 16px !important;
}
.greenish-badge:hover {
    color: #10c469;
    border-color: #10c469;
    transition: all 0.4s ease;
}
.redish-badge:hover {
    color: #ff5b5b;
    border-color: #ff5b5b;
    transition: all 0.4s ease;
}
.finish-date {
    line-height: 24px;
    font-size: 12px !important;
}
</style>