<template>
    <div class="form-group">
        <div class="input-group">
            <input type="text" class="form-control" placeholder="название подзадачи" v-model="form.title" @keyup.enter="submitForm()">
            <div class="input-group-append">
                <button v-if="!loading" class="btn btn-primary" type="button"  @click.prevent="submitForm()">Создать</button>
                <button v-else class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Загрузка...
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubtaskCreate',
    props: ['task'],
    data: () => {
		return {
			_form: {
				title: '',
			},
			form: {},
			loading: false,
		};
    },
    mounted() {
		this.resetForm();
    },
    methods: {
        resetForm() {
			this.form = Object.assign({}, this._form);
		},
		async submitForm() {
			if (this.loading) return $.NotificationApp.send('Ошибка', 'Пожалуйста, подождите', 'top-right', '', 'error');

			if (!this.form.title)
				return $.NotificationApp.send('Ошибка', 'Пожалуйста, введите название', 'top-right', '', 'error');

            this.loading = true;

            var [err, response] = await this.$orm.mutate({
                subtask: {
                    insert: {
                        objects: {
                            task_id: this.task.id,
                            title: this.form.title
                        }
                    }
                },
                project: {
                    update: {
                        where: { id: { _eq: this.task.project_id } },
                        _set: { updated_at_trigger: true }
                    }
                },
                task: {
                    update: {
                        where: { id: { _eq: this.task.id } },
                        _set: { updated_at_trigger: true },
                        fields: ['id']
                    }
                }
            })

			if (err) {
				this.loading = false;
				log.error('code-modal', 'methods.submitForm', '$http.request', err);
				return $.NotificationApp.send('Ошибка', err.message, 'top-right', '', 'error');
			}
			if (response.error) {
				this.loading = false;
				log.error('code-modal', 'methods.submitForm', '$http.response', response);
				return $.NotificationApp.send('Ошибка', response, 'top-right', '', 'error');
			}
			this.loading = false;

			this.resetForm();
		},
    }
}
</script>

<style>

</style>