<template>
    <div class="list-group-item list-group-item-light">
        <s-parts-finished-widget class="mt-1" :subtask="subtask" :timers="timers" :project_id="project_id" />

        <h5 class="mt-2 card-title">
            <span class="">{{ subtask.title }}</span>
        </h5>

        <div class="mb-1 description" v-if="subtask.description">
            <div v-html="subtask.description"></div>
        </div>
    </div>
</template>

<script>
import SPartsFinishedWidget from '@/components/subtask/_parts/FinishedWidget.vue'

export default {
    name: 'SubtaskCard',
    props: ['subtask', 'timers', 'project_id'],
    components: {
        SPartsFinishedWidget
    }
}
</script>

<style scoped>
.list-group-item-light {
    padding: 0.25rem 1rem;
}
.widget-block {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
}
.pull-right {
    float: right;
}
</style>