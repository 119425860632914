<template>
    <div class="card border-secondary border mb-4">
        <div class="card-body pb-3">
            <t-parts-widget :task="task" :timers="task.timers" :task_description="rf.task_description" />

            <h4 class="mt-0 mb-3 card-title">
                <text-editable :styles="{'min-width': '300px', 'max-width': '100%'}" :editable="task.title" @update:editable="editItem({key: 'title', value: $event})">
                    <span class="">{{ task.title }}</span>
                </text-editable>
            </h4>

            <div v-if="task.is_finished" class="badge badge-success mb-3">Finished</div>

            <div class="mb-3">
                <t-parts-notification :task="task" />
            </div>

            <div class="mb-2 description">
                <textarea-editable :styles="{ width: '100%' }" :object="{ project_id: task.project_id, task_id: task.id }" ref="task_description" :editable="task.description" @update:editable="editItem({key: 'description', value: $event})">
                    <div v-html="task.description ? task.description : 'Дважды нажмите здесь, чтобы создать описание'"></div>
                </textarea-editable>
            </div>

            <t-parts-stats class="mb-1" :task="task" />

            <t-parts-members :task="task" />
        </div>

        <task-progress :task="task" />
    </div>
</template>

<script>
import TextEditable from '@/components/editable/TextEditable.vue'
import TextareaEditable from '@/components/editable/TextareaEditable.vue'
import TaskProgress from '@/components/progress/TaskProgress.vue'

import TPartsWidget from '@/components/task/_parts/Widget.vue'
import TPartsMembers from '@/components/task/_parts/Members.vue'
import TPartsStats from '@/components/task/_parts/Stats.vue'
import TPartsNotification from '@/components/task/_parts/Notification.vue'

export default {
    name: 'TaskForm',
    props: ['task'],
    data: () => {
        return {
            rf: {
                task_description: null
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.rf.task_description = this.$refs.task_description
        });
    },
    methods: {
        async editItem(options = {}) {
            let {key, value} = options

			if(key) {
                this.$set(this.task, key, value)
                
                await this.$orm.mutate({
                    task: {
                        update: {
                            where: { id: { _eq: this.task.id }},
                            _set: { [key]: value }
                        }
                    },
                    project: {
                        update: {
                            where: { id: { _eq: this.task.project_id } },
                            _set: { updated_at_trigger: true },
                            fields: ['id']
                        }
                    }
                })
            }
        }
    },
    components: {
        TextEditable,
        TextareaEditable,
        TaskProgress,

        TPartsWidget,
        TPartsMembers,
        TPartsStats,
        TPartsNotification
    }
}
</script>

<style>

</style>