<template>
	<ul class="list-group list-group-flush mb-2" v-if="task.subtasks.length">
		<li class="list-group-item">
			<!-- project progress-->
			<p class="mb-2 font-weight-bold">Прогресс <span class="float-right">{{ percent }}%</span></p>
			<div class="progress progress-sm">
				<div class="progress-bar" role="progressbar" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${percent < 1 ? 1 : percent}%`}">
				</div><!-- /.progress-bar -->
			</div><!-- /.progress -->
		</li>
	</ul>
</template>

<script>
export default {
	name: 'TaskProgress',
	props: [ 'task' ],
	data: () => {
		return {
			
		}
	},
	computed: {
		percent() {
			return parseInt( 
				this.task.subtasks.reduce((acc, item) => {
					return acc += (item.is_finished ? 1 : 0)
				}, 0) * 100 / this.task.subtasks_aggregate.aggregate.count
			)
		}
	},
	methods: {
		
	}
}
</script>

<style scoped>
.list-group-item {
	padding: 1.25rem;
	padding-top: 0px !important;
}
</style>