<template>
    <div>
        <div class="row mb-2">
			<div class="col-sm-12">
				<div class="text-sm-right" v-if="item !== null">
					<div class="btn-group">
						<router-link :to="{ name: 'ProjectView', params: { id: item.project_id } }">
							<i class="dripicons-backspace"></i> назад в проект 
						</router-link>
					</div>
				</div>
			</div><!-- end col-->
		</div>

        <!-- LOADING -->
		<div class="row" v-if="item === null">
			<div class="col-md-12 text-center">
				<div class="spinner-grow" role="status">
					<span class="sr-only">Загрузка...</span>
				</div>
			</div>
		</div>

        <!-- LOADED -->
		<div class="row" v-else>

		<!-- LEFT COLUMN -->
			<div class="col-md-12 col-lg-12 col-xl-6 mb-3">
				<subtask-create :task="item" />

				<transition-group class="list-group sub-task-list" name="flip-list" tag="div">
					<subtask-card v-for="subtask in activeSubtasks" :key="subtask.id" :subtask="subtask" :project_id="item.project_id" :timers="item.timers" />
				</transition-group>
				<hr>
				<transition-group class="list-group sub-task-list" name="flip-list" tag="div">
					<subtask-finished-card v-for="subtask in finishedSubtasks" :key="subtask.id" :subtask="subtask" :project_id="item.project_id" :timers="item.timers" />
				</transition-group>
			</div>

		<!-- RIGHT COLUMN -->
			<div class="col-md-12 col-lg-12 col-xl-6">
				<task-form :task="item" />

				<timer-chart title="задачу" :timers="item.timers" :users="[item.owner, ...item.project.guests.map(g => g.user)]" />
			</div>
		</div>
    </div>
</template>

<script>
import log from '@/plugins/log'

import TaskForm from '@/components/task/Form'

import SubtaskCreate from '@/components/subtask/Create'
import SubtaskCard from '@/components/subtask/Card'
import SubtaskFinishedCard from '@/components/subtask/FinishedCard'

import TimerChart from '@/components/timer/TimerChart.vue'

export default {
    name: 'ProjectTaskVue',
    data: () => {
		return {
			item: null,
			unsubsribe: null,
			timers: null
		}
    },
    computed: {
        id() {
			return this.$route.params.task_id
		},
		activeSubtasks() {
			return this.item.subtasks.filter(item => {				
				return item.is_finished === false
			})
		},
		finishedSubtasks() {
			return this.item.subtasks.filter(item => {				
				return item.is_finished === true
			})
		}
    },
    async beforeMount() {
		await this.data()
	},
	beforeRouteLeave(to, from , next) {
		if(typeof this.unsubsribe == 'function') {
			this.unsubsribe()
			this.$store.commit('navigation/set_meta', {})
		}
			
		next()
    },
    methods: {
		async data() {
			this.unsubsribe = await this.$orm.queryAndSubscribe({
				task: {
					where: { id: { _eq: this.id } },
					fragment: 'w_subtasks'
				}
			}, ([err, response]) => {
				if(err) {
					log.error('/views/project/view', 'methods.data', '$orm.queryAndSubscribe', err)
					return $.NotificationApp.send("Ошибка", "Не получается отправить запрос", "top-right", "", "error")
				}
				log.debug('/views/project/view', 'methods.data', '$orm.queryAndSubscribe', response)
				this.item = response
				
				this.$store.commit('navigation/set_meta', {
					link: { name: 'TaskView', params: { id: response.project_id, task_id: response.id } },
					title: `<span class="text-muted">Задача:</span> ${response.title}`
				})
                
                this.$route.meta.title = this.item.title
			}, { getFirst: true })
        },
	},
	watch: {
		'$route.params': async function(to, from) {
			await this.data()
		}
	},
    components: {
		TaskForm,
		SubtaskCreate,
		SubtaskCard,
		SubtaskFinishedCard,
		TimerChart
    }
}
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
</style>