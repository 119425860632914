<template>
    <div class="color-wrapper">
        <router-link :to="{ name: 'SettingsNotification' }">
            <i class="mdi mdi-bell mr-2" title="Подписка на уведомления по gjlзадачам"></i>
        </router-link>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="proj-task-noti-created" v-model="form.created">
            <label class="custom-control-label" for="proj-task-noti-created">Новые</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="proj-task-noti-finished" v-model="form.finished">
            <label class="custom-control-label" for="proj-task-noti-finished">Выполненные</label>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { sleep, mergeDeep } from '@/plugins/utils'

export default {
    name: 'TaskNotification',
    props: ['task'],
    data: () => {
        return {
            watching: false,
            form: {
                created: false,
                finished: false
            }
        }
    },
    computed: {
		...mapState({
			user: state => state.auth.user,
			settings: state => state.settings,
		})
	},
    mounted() {
        if(this.user.notification_settings) {
            if(this.user.notification_settings['subtask:new']) {
                if(this.user.notification_settings['subtask:new'].settings[this.task.id])
                    this.form.created = true
            }
            if(this.user.notification_settings['subtask:finished']) {
                if(this.user.notification_settings['subtask:finished'].settings[this.task.id])
                    this.form.finished = true
            }
        }
        this.watching = true
    },
    methods: {

    },
    watch: {
        form: {
            deep: true,
            async handler() {
                if(!this.watching) return;

                this.$store.commit('auth/create_notification_object', { key: 'subtask:new' })
                this.$store.commit('auth/create_notification_object', { key: 'subtask:finished' })

                let notification_settings = this.user.notification_settings

                if(this.form.created === true) {
                    notification_settings['subtask:new'].enabled = true
                    notification_settings['subtask:new'].settings[this.task.id] = this.task.project_id
                } else {
                    delete notification_settings['subtask:new'].settings[this.task.id]
                }

                if(this.form.finished === true) {
                    notification_settings['subtask:finished'].enabled = true
                    notification_settings['subtask:finished'].settings[this.task.id] = this.task.project_id
                } else {
                    delete notification_settings['subtask:finished'].settings[this.task.id]
                }

                this.$store.commit('auth/set_user_notification_settings', notification_settings)
                await this.$store.dispatch('auth/update_notification_settings')
            }
        }
    }
}
</script>