<template>
    <div>
        <timer-badge :object="{ project_id, task_id: subtask.task_id, subtask_id: subtask.id }" :timers="timers"/>

        <router-link class="badge badge-outline-secondary mr-1" :to="{ name: 'TimerList', params: { project_id: project_id, task_id: subtask.task_id, subtask_id: subtask.id } }" title="Таймеры подзадачи" >
            <i class="mdi mdi-timetable"></i>
        </router-link>

        <a href="#" title="Редактировать описание" @click.prevent="editDescription()" class="badge badge-outline-secondary mr-1">
            <i class="mdi mdi-clipboard-text-outline"></i>
        </a>
        
        <a href="#" title="Удалить подзадачу" @click.prevent="deleteItem()" class="badge badge-outline-secondary redish-badge">
            <i v-if="!loading.delete" class="mdi mdi-delete"></i>
            <i v-else class="spinner-border spinner-border-sm"></i>
        </a>
    </div>
</template>

<script>
import TimerBadge from '@/components/timer/TimerBadge.vue' 

export default {
    name: 'SubtaskCardWidget',
    props: ['subtask', 'timers', 'project_id', 'description_editable'],
    data: () => {
        return {
            loading: {
                delete: false
            }
        }
    },
    methods: {
        async deleteItem() {
            if(!confirm(`Delete ${this.subtask.title} ?`))
                return false

            this.loading.delete = true
            var [err, response] = await this.$orm.mutate({
                subtask: {
                    update: {
                        where: { id: { _eq: this.subtask.id }},
                        _set: { is_deleted: true }
                    }
                },
                project: {
                    update: {
                        where: { id: { _eq: this.project_id } },
                        _set: { updated_at_trigger: true }
                    }
                },
                task: {
                    update: {
                        where: { id: { _eq: this.subtask.task_id } },
                        _set: { updated_at_trigger: true },
                        fields: ['id']
                    }
                }
            })
            if(!err && !response.error)
                this.subtask.is_deleted = true
            
            this.loading.delete = false
        },
        createDescription() {
            this.$set(this.subtask, 'description', 'Edit description here')
        },
        editDescription() {
            if(!this.subtask.subtask)
                this.$set(this.subtask, 'description', ' ')
            
            setTimeout(() => {
                this.$emit('edit_description')
            }, 50)
        }
    },
    components: {
        TimerBadge
    }
}
</script>

<style scoped>
div {
    font-size: 16px !important;
}
.greenish-badge:hover {
    color: #10c469;
    border-color: #10c469;
    transition: all 0.4s ease;
}
.redish-badge:hover {
    color: #ff5b5b;
    border-color: #ff5b5b;
    transition: all 0.4s ease;
}
</style>