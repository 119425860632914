<template>
    <div>
        <a href="javascript:void(0);" rel="tooltip" :title="task.owner.name" class="d-inline-block mr-1">
            <profile-avatar :user="task.owner" gclass="rounded-circle avatar-xs" />
        </a>
    </div>
</template>

<script>
import ProfileAvatar from '@/components/profile/Avatar.vue'

export default {
    name: 'TaskCardMembers',
    props: ['task'],
     components: {
        ProfileAvatar
    }
}
</script>

<style>

</style>